import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    createTemplate: (ctx, data) => axiosIns.post('/api.template', data),
    fetchTemplates: () => axiosIns.get('/api.template'),
    fetchTemplate: (ctx, { id }) => axiosIns.get(`/api.template/${id}`),
    updateTemplate: (ctx, data) => axiosIns.put(`/api.template/${data.id}`, data),
    deleteTemplate: (ctx, { id }) => axiosIns.delete(`/api.template/${id}`),
    updatePriorityTemplates: (ctx, ids) => axiosIns.put('/api.template/update-priority', ids),
    renderTemplate: (ctx, data) => axiosIns.post('/api.template/render', data),
  },
}
