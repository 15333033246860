import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchMeta: (ctx, { name }) => axiosIns.get(`/api.meta/${name}`),
    updateMeta: (ctx, { name, meta_value }) => axiosIns.put(`/api.meta/${name}`, { meta_value }),
  },
}
