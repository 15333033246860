<template>
  <div>
    <b-button
      ref="btn-open-modal-department"
      v-b-modal="id"
      class="d-none"
    />
    <b-modal
      :id="id"
      :ok-title="modalMode === 'create' ? 'Tạo' : 'Sửa'"
      cancel-title="Hủy"
      header-text-variant="primary"
      :title="(modalMode === 'create' ? 'Tạo' : 'Chỉnh sửa') + ' phòng ban'"
      @ok="handleOk"
    >
      <b-form-group
        label="Tên phòng ban"
        label-for="department_name"
      >
        <b-form-input
          id="department_name"
          v-model="department.name"
          placeholder="Tên phòng ban"
        />
      </b-form-group>
      <b-form-group
        label="Mã phòng ban"
        label-for="department_code"
      >
        <b-form-input
          id="department_code"
          v-model="department.code"
          placeholder="Mã phòng ban"
        />
      </b-form-group>
      <span
        v-if="modalErrorText"
        class="text-danger"
      >{{ modalErrorText }}</span>
    </b-modal>
  </div>
</template>

<script>
import { BButton, BFormGroup, BFormInput } from 'bootstrap-vue'
import { computed, onUnmounted, ref } from '@vue/composition-api'
import http from '@/global/http'
import store from '@/store'
import demartmentStoreModule from '@/views/settings/departments/departmentStoreModule'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BButton,
  },
  props: {
    id: {
      type: String,
      default: 'create',
    },
    modalMode: {
      type: String,
      default: 'create',
    },
    modalData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const DEPARTMENT_STORE_MODULE_NAME = 'department'
    if (!store.hasModule(DEPARTMENT_STORE_MODULE_NAME)) store.registerModule(DEPARTMENT_STORE_MODULE_NAME, demartmentStoreModule)
    onUnmounted(() => {
      if (store.hasModule(DEPARTMENT_STORE_MODULE_NAME)) store.unregisterModule(DEPARTMENT_STORE_MODULE_NAME)
    })

    const department = computed(() => ({
      ...props.modalData,
    }))

    const modalErrorText = ref('')

    const updateDepartment = d => {
      http.handle(store.dispatch('department/updateDepartment', d), res => {
        emit('updated', res.data)
      })
    }

    const handleOk = bvModalEvt => {
      if (props.modalMode === 'create') {
        if (!department.value.name || !department.value.code) {
          modalErrorText.value = 'Không được để trống'
          bvModalEvt.preventDefault()
        } else {
          modalErrorText.value = ''
          http.handle(store.dispatch('department/createDepartment', department.value), res => {
            emit('created', res.data)
          })
        }
      } else {
        updateDepartment(department.value)
      }
    }

    return {
      handleOk,
      updateDepartment,
      department,
      modalErrorText,
    }
  },
}
</script>
